import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
    },
    refreshButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

interface Props {
  onRefreshButtonClicked?: ()=>void;
  displayRefreshButton: boolean;
}

export default function TitleBar(props: Props) {
  const classes = useStyles();

  const onRefreshButtonClicked = () => {
    props.onRefreshButtonClicked && props.onRefreshButtonClicked();
  };

  var refreshButton = undefined;
  if (props.displayRefreshButton) {
    refreshButton = <IconButton edge="end" className={classes.refreshButton} color="inherit" aria-label="refresh" onClick={onRefreshButtonClicked}>
                      <RefreshIcon />
                    </IconButton>;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Pass Word
          </Typography>
          {refreshButton}
        </Toolbar>
      </AppBar>
    </div>
  );
}