import React, { Component } from "react";
import FormButton from "./FormButton";
import TextField from '@material-ui/core/TextField';

interface Props {
    hideForm?: ()=>void;
    submit?: (data: State)=>boolean;
}

export interface State {
    passWord: string,
    nickname: string,
    latitude: number | null,
    longitude: number | null,
    altitude: number | null,
    [key: string]: any,

    isValidated: boolean,
}

class PassWordSubmissionForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
          passWord: "",
          nickname: "",
          latitude: null,
          longitude: null,
          altitude: null,
          isValidated: false,
        };
    }
    
    private handleInputChange = (event: any) => {
        const target = event.target;
        const name = target.name;
        var value = target.type === 'checkbox' ? target.checked : target.value;

        if (name === "passWord") {
            value = (""+value).slice(0, 30);
            if (value.length > 0) {
                this.setState({
                    isValidated: true,
                });
            } else {
                this.setState({
                    isValidated: false,
                });
            }
        }

        if (name === "nickname") {
            value = (""+value).slice(0, 50);
        }
    
        this.setState({
          [name]: value
        });
      }

    private hideInputForm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.hideForm && this.props.hideForm();
    };

    private submitForm = () => {
        var ret = true; // if submit is undefined then by default run hideForm()
        this.props.submit && (ret = this.props.submit(this.state));
        if (ret) {
            this.props.hideForm && this.props.hideForm();
        }
    }

    private fillUserLocation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    altitude: position.coords.altitude,
                });
              });
        }
    }

    render() {
        this.fillUserLocation();
        return (
            <form className="password-submission-form">
                <TextField
                    id="pass-word"
                    label="Pass Word"
                    placeholder="说些什么（最多 30 个字符）"
                    helperText="这里的文字会被展示在展览现场"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                    shrink: true,
                    }}
                    name="passWord"
                    value={this.state.passWord}
                    onChange={this.handleInputChange}
                />
                <TextField
                    id="nickname"
                    label="昵称"
                    placeholder="你的昵称（可选）"
                    helperText="并没有什么卵用"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                    shrink: true,
                    }}
                    name="nickname"
                    value={this.state.nickname}
                    onChange={this.handleInputChange}
                />
                <div className="horizontal-centering">
                    <FormButton color='default' onClick={this.hideInputForm} disabled={false}>
                        取消
                    </FormButton>
                    <FormButton color='primary' onClick={this.submitForm} disabled={!(this.state.isValidated)}>
                        发送
                    </FormButton>
                </div>
            </form>
        );
    }
}

export default PassWordSubmissionForm;