import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    card: {
        minWidth: 275,
        marginLeft: 8,
        marginRight: 8,
        marginTop: 12,
        marginBottom: 12,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        paddingTop: 16,
        marginBottom: 2,
    },
});

interface Props {
    ssid: string;
    nickname: string;
    time: Date;
}

export default function PassWordCard(props: Props) {
    const classes = useStyles();
    var date = new Date(props.time);
    var time_string = `${date.getFullYear()} 年 ${date.getMonth() + 1} 月 ${date.getDate()} 日 ${(""+date.getHours()).padStart(2, "0")}:${(""+date.getMinutes()).padStart(2, "0")}`;

    return (
        <Card className={classes.card}>
            <CardContent>
                {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Pass Word
                </Typography> */}
                <Typography variant="h5" component="h2">
                    {props.ssid}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                    @{props.nickname === "unknown" ? "佚名" : props.nickname}<br />
                    提交于 {"" + time_string}
                </Typography>
            </CardContent>
        </Card>
    );
}