import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
  }),
);

interface Props {
    color?: 'inherit' | 'default' | 'primary' | 'secondary' | undefined;
    children?: any;
    onClick?: (e:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>void;
    disabled: boolean;
}

export default function FormButton(props: Props) {
  const classes = useStyles();

  return (
      <Button variant="contained" className={classes.button} color={props.color} onClick={props.onClick} disabled={props.disabled}>
        {props.children}
      </Button>
  );
}