import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerbound: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    progress: {
      margin: theme.spacing(2),
    },
  }),
);

interface Props {
    color?: 'inherit' | 'primary' | 'secondary' | undefined;
    show?: boolean;
}

export default function RefreshIndicator(props: Props) {
  const classes = useStyles();

  if (props.show) {
    return (
        <div className={classes.outerbound}>
          <CircularProgress className={classes.progress} color={props.color} />
        </div>
      );
  } else {
      return null;
  }
}