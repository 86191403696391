import React, { Component } from "react";
import AddButton from "./AddButton";
import PassWordSubmissionForm from "./PassWordSubmissionForm";
import { PassWordEntry } from "../models/PassWordEntry";
import PassWordList from "./PassWordList";
import { State as PassWordSubmissionFormState } from "./PassWordSubmissionForm";

interface State {
    isInputFormShown: boolean,
}

interface Props {
    isRefreshing?: boolean;
    PassWordCollection: PassWordEntry[];
    onNewPassWordSubmission?: (data: PassWordSubmissionFormState) => boolean;
}

class PageSwitcher extends Component<Props, State> {
    state = {
        isInputFormShown: false,
    };

    private showInputForm = () => {
        this.setState({
            isInputFormShown: true,
        });
    };

    private hideInputForm = () => {
        this.setState({
            isInputFormShown: false,
        });
    };

    render() {
        if (this.state.isInputFormShown) {
            return <div>
                <PassWordSubmissionForm hideForm={this.hideInputForm} submit={this.props.onNewPassWordSubmission} />
            </div>;
        }
        else {
            return (
                <div>
                    <PassWordList PassWordCollection={this.props.PassWordCollection} isRefreshing={this.props.isRefreshing}/>
                    <AddButton onClick={this.showInputForm} />
                </div>
            );
        }
    }
}

export default PageSwitcher;