import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: "fixed",
      right: 0,
      bottom: 0,
      margin: theme.spacing(4),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }),
);

interface Props {
    onClick: ()=>void;
}

export default function AddButton(props: Props) {
  const classes = useStyles();

  return (
    <div>
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={props.onClick}>
        <AddIcon />
      </Fab>
    </div>
  );
}