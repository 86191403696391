import React, { Component } from "react";
import TitleBar from "./components/TitleBar";
import PageSwitcher from "./components/PageSwitcher";
import { PassWordEntry } from "./models/PassWordEntry";
import { State as PassWordSubmissionFormState } from "./components/PassWordSubmissionForm";

interface State {
  PassWordCollection: PassWordEntry[];
  isRefreshing?: boolean;
}

class App extends Component<{}, State> {
  state = {
    PassWordCollection: [],
    isRefreshing: false,
  };

  private updateList = () => {
    this.setState({
      isRefreshing: true,
    });
    fetch(process.env.REACT_APP_API_BASE_URI + '/v1/pass-word?count=30', {
      mode: 'cors', // note: if we set "no-cors", the headers are immutable https://stackoverflow.com/a/38163435/2646069
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        return response.json();
      }).then((responseJson) => {
        this.setState({
          PassWordCollection: responseJson.results,
          isRefreshing: false,
        });
      });
  }

  private onNewPassWordSubmission = (data: PassWordSubmissionFormState): boolean => {
    fetch(process.env.REACT_APP_API_BASE_URI + "/v1/pass-word", {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify({
        "ssid": data.passWord,
        "nickname": data.nickname,
        "latitude": data.latitude,
        "longitude": data.longitude,
        "altitude": data.altitude,
      })
    })
    .then(response => {
      console.log('Success:', JSON.stringify(response));
      this.updateList();
    })
    .catch(error => console.error('Error:', error));
    return true;
  }

  componentDidMount() {
    this.updateList();
  }

  componentWillUnmount() {

  }

  render() {
    return (
      <div className="AppRoot">
        <div className="TitleBarContainer">
          <TitleBar onRefreshButtonClicked={this.updateList} displayRefreshButton={true} />
        </div>
        <div className="PageContainer">
          <PageSwitcher PassWordCollection={this.state.PassWordCollection} onNewPassWordSubmission={this.onNewPassWordSubmission} />
        </div>
      </div>
    );
  }
}

export default App;