import React, { Component } from "react";
import PassWordCard from "./PassWordCard";
import RefreshIndicator from "./RefreshIndicator";
import {PassWordEntry} from "../models/PassWordEntry";


interface Props {
    isRefreshing?: boolean;
    PassWordCollection: PassWordEntry[];
}

class PassWordList extends Component<Props, {}> {
    render() {
        const cards = this.props.PassWordCollection.map((word) => 
            <PassWordCard key={word.id} ssid={word.ssid} nickname={word.user_nickname || "unknown"} time={new Date(word.created_at)} />
        );
        return (
            <div>
                <RefreshIndicator show={this.props.isRefreshing} />
                {cards}
            </div>
        );
    }
}

export default PassWordList;